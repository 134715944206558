/**
* @author ayou[beica1@outook.com]
* @date 2022/1/4 14:11
* @description
*   TheEvents.vue of WeTrade
*/
<template>
<div class="df-middle nowrap">
  <div class="time-item df-middle nowrap">
    <span class="c-theme clock-bg f-bold f-nm">{{ value[0].split(':')[0] }}</span>
    <span class="f-bold f-nm c-white mx-4">:</span>
    <span class="c-theme clock-bg f-bold f-nm">{{ value[0].split(':')[1] }}</span>
  </div>
  <div v-if="section" class="df-middle nowrap">
    <span class="f-bold f-nm c-white mx-8">-</span>
    <div class="time-item df-middle nowrap">
      <span class="c-theme clock-bg f-bold f-nm">{{ value[1].split(':')[0] }}</span>
      <span class="f-bold f-nm c-white mx-4">:</span>
      <span class="c-theme clock-bg f-bold f-nm">{{ value[1].split(':')[1] }}</span>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheClock',
  props: {
    value: {
      type: Array,
      required: true,
      default: () => ['--:--', '--:--'],
    },
    section: Boolean,
  },
  setup () {
    return {}
  },
})
</script>

<style scoped lang="scss">
.clock-bg{
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: var(--color-theme-reverse);
  border-radius: 3px;
  display: inline-block;
}
.nowrap{
  flex-wrap: nowrap;
}
</style>
