<template>
  <DialogTemplate>
    <template #title>
      <t path="gacha_7">Gacha</t>
    </template>
    <div class="t-center">
      <t as="p" :path="path" class="px-32 pb-20">
        It’s not Gacha’s time, please come later.
      </t>
    </div>
    <template #controls>
      <Button class="flex-1 primary btn block" @click="exitClick">
        <t path="gacha_9">Exit</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'GacheLimitDialog',
  components: { DialogTemplate },
  props: {
    path: String,
  },
  setup (props, ctx) {
    const exitClick = () => {
      ctx.emit('close')
    }
    return {
      exitClick,
    }
  },
})
</script>
