<template>
<PageWithHeader style="background-color: #470AB6" header-class="bg-white">
  <template #title>
    <t path="gacha_2">Gacha</t>
  </template>
  <div class="main-wrap">
    <div class="clock-wrap df-center">
      <Clock :value="gacheTime" section />
    </div>
    <div class="notice df-middle px-8">
      <Pic src="events/cardGame/notice" width="24" height="24" />
      <div class="p-wrap pr-8 ml-8 f-md c-white">
        <p v-if="noticeList" :style="`animation-duration:${noticeList.length*3}s`">
          <span class="mr-16 f-md" v-for="(item, index) in noticeList" :key="index">
            {{item.name}} <t path="gacha_3">got</t> {{item.denomination}} <t
            :path="item.name===1?'gacha_4':'gacha_5'"></t>
          </span>
        </p>
      </div>
    </div>
    <img v-show="showPoint" class="point-img" src="/img/events/cardGame/point@2x.png" alt="">
    <img class="box-bg" src="/img/events/cardGame/box@2x.png" />
    <div class="init-po" v-if="!hideInit">
      <div class="init-card" :class="{step1: step1}" @click="start"></div>
    </div>
    <div class="card-wrap"
      v-show="hideInit"
      v-for="(item, index) in cardInfo"
      :key="index"
      :id="'card_' + index">
      <div class="front df-col df-middle df-center"
        :style="`background-image: url(${item.type===1?'/img/events/cardGame/coupon_bg@2x.png':'/img/events/cardGame/point_bg@2x.png'});`">
        <!-- <div class="front df-col df-middle df-center"
          :style="`background-image: url(${item.icon});`"> -->
        <t class="c-white f-xl mb-4 f-bold">{{(item.type === 1 ? '$' : '') + item.denomination}}</t>
        <!-- <money v-if="item.type ===1" v="item.denomination" />
        <span v-else>{{ item.denomination }}</span> -->
        <t :path="item.type ===1?'gacha_4':'gacha_5'" class="c-white f-nm">
          {{item.type === 1 ? 'Coupon' : 'Point'}}
        </t>
      </div>
      <div class="back"></div>
    </div>
    <div class="holder-wrap">
      <div class="holder-item"></div>
      <div class="holder-item"></div>
      <div class="holder-item"></div>
    </div>
    <div class="tips p-32 f-md">
      <t path="gacha_6" multiline #="{td}" custom>
        <p class="mb-8" v-for="(p, index) in td" :key="index">{{p}}</p>
      </t>
      <!-- <t as="p" class="f-nm mb-12">Term of event</t>
      <t as="p" class="mb-8">1. Coupons can only be used for transactions and cannot be withdrawn.</t>
      <t as="p">2. There are no handling fees and overnight fees for using coupon transactions.</t> -->
    </div>
  </div>
  <audio ref="audio" @canplay="onMediaReady" />
</PageWithHeader>
</template>
<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, shallowRef } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Clock from '@/events/cardGame/components/TheClock.vue'
import Pic from '@/components/Pic.vue'
import { readGache, goGache } from '@/events/cardGame/cardGame.api'
import GacheLimitDialog from '@/events/cardGame/components/GacheLimitDialog.vue'
import { openDialog, showAlert } from '@/components/popup/popup'
import { useRouter } from 'vue-router'
import { formatDateTime } from '@/common/format'

export default defineComponent({
  name: 'TheCardGame',
  components: { PageWithHeader, Clock, Pic },
  setup () {
    const audio = shallowRef<HTMLAudioElement | null>(null)
    const audioReady = shallowRef(false)
    const router = useRouter()
    const cardInfo = shallowRef()
    const step1 = shallowRef(false)
    const hideInit = shallowRef(false)
    const canDraw = shallowRef(false)
    const showPoint = shallowRef(false)
    const noticeList = shallowRef()
    const gacheTime = shallowRef()
    const timer = {
      timer_1: 0,
      timer_2: 0,
      timer_3: 0,
    }

    // 初始化抽卡信息
    const getActInfo = async () => {
      const res = await readGache()

      gacheTime.value = [
        formatDateTime('HH:mm', res.startTime),
        res.endTime ? formatDateTime('HH:mm', res.endTime) : '--:--',
      ]

      noticeList.value = res.infoModels

      if (res.status === 0) {
        // 活动时间未开始
        openDialog(GacheLimitDialog, {
          path: 'gacha_8',
          onClose: router.back,
        })
      } else if (res.status === 1) {
        // 可以参与抽奖
        canDraw.value = true
        showPoint.value = true
      } else if (res.status === 2) {
        // 已经抽过奖
        hideInit.value = true
        cardInfo.value = res.gachaInfoModelList

        for (let i = 0; i < cardInfo.value.length; i++) {
          setTimeout(() => {
            document.getElementById('card_' + i)?.classList.add('step3_' + i)
            document.getElementById('card_' + i)?.classList.add('step4_' + i)
          }, 0)
        }
      } else if (res.status === 3) {
        // 用户没有做单
        openDialog(GacheLimitDialog, {
          path: 'gacha_11',
          onClose: router.back,
        })
      }
    }

    getActInfo()

    // 点击抽卡操作
    const start = async () => {
      if (!canDraw.value || !audioReady.value) {
        return false
      }

      const res = await goGache()
      showPoint.value = false
      cardInfo.value = res.infoModels

      // 确保返回中奖信息为数组并且固定含有三个元素
      if (!Array.isArray(cardInfo.value) || cardInfo.value.length === 0) {
        showAlert('prize list is empty!')
        return false
      } else if (cardInfo.value.length > 3) {
        cardInfo.value = cardInfo.value.slice(0, 3)
      }

      // 执行动画第一步
      step1.value = true
      spread()
    }

    // 卡片展开动画
    const spread = () => {
      timer.timer_1 = window.setTimeout(() => {
        hideInit.value = true
        for (let i = 0; i < cardInfo.value.length; i++) {
          document.getElementById('card_' + i)?.classList.add('step2_' + i)
        }
        setTurn()
      }, 500)
    }

    // 卡片翻转动画
    const setTurn = () => {
      for (let i = 0; i < cardInfo.value.length; i++) {
        timer.timer_2 = window.setTimeout(() => {
          document.getElementById('card_' + i)?.classList.add('step3_' + i)
          setPosition(i)
          if (audio.value) {
            audio.value.currentTime = 0
            audio.value.play()
          }
        }, i * 1000 + 800)
      }
    }

    // 卡片翻转后归位动画
    const setPosition = (i: number) => {
      timer.timer_3 = window.setTimeout(() => {
        document.getElementById('card_' + i)?.classList.add('step4_' + i)
      }, 1000)
    }

    onMounted(() => {
      if (audio.value) {
        audio.value.src = '/media/gacha.mp3'
        audio.value.load()
      }
    })

    onBeforeUnmount(() => {
      clearTimeout(timer.timer_1)
      clearTimeout(timer.timer_2)
      clearTimeout(timer.timer_3)
    })

    return {
      onMediaReady () {
        audioReady.value = true
      },
      audio,
      gacheTime,
      start,
      noticeList,
      step1,
      hideInit,
      showPoint,
      cardInfo,
    }
  },
})
</script>

<style>
@keyframes step1 {
  from {
    top: 218px;
    transform: rotate(-20deg) translate(-50%);
  }
  to {
    top: 135px;
    transform: rotate(0) translate(-50%);
  }
}

/* 第二步 */
@keyframes step2_0 {
  from {
    top: 135px;
  }
  to {
    left: 16px;
    margin-left: 54px;
  }
}

@keyframes step2_1 {
  from {
    top: 135px;
  }
  to {
    left: 50%;
  }
}

@keyframes step2_2 {
  from {
    top: 135px;
  }
  to {
    left: calc(100% - 16px);
    margin-left: -54px;
  }
}

/* 第三步 */
@keyframes step3_0 {
  from {
    left: 16px;
    transform: rotateY(0);
  }
  to {
    left: 16px;
    transform: rotateY(180deg);
  }
}

@keyframes step3_1 {
  from {
    left: 50%;
    margin-left: -54px;
    transform: rotateY(0);
  }
  to {
    left: 50%;
    margin-left: -54px;
    transform: rotateY(180deg);
  }
}

@keyframes step3_2 {
  from {
    left: 100%;
    margin-left: -124px;
    transform: rotateY(0);
  }
  to {
    left: 100%;
    margin-left: -124px;
    transform: rotateY(180deg);
  }
}

/* 第四步 */
@keyframes step4_0 {
  from {
    left: 16px;
    transform: rotateY(180deg);
  }
  to {
    left: 36px;
    top: 405px;
    width: 80px;
    height: 102px;
    transform: rotateY(180deg);
  }
}

@keyframes step4_1 {
  from {
    left: 50%;
    margin-left: -54px;
    transform: rotateY(180deg);
  }
  to {
    top: 405px;
    width: 80px;
    height: 102px;
    left: 50%;
    margin-left: -40px;
    transform: rotateY(180deg);
  }
}

@keyframes step4_2 {
  from {
    left: 100%;
    margin-left: -124px;
    transform: rotateY(180deg);
  }
  to {
    top: 405px;
    width: 80px;
    height: 102px;
    left: 100%;
    margin-left: -116px;
    transform: rotateY(180deg);
  }
}

@keyframes wordsLoop {
  0% {
    transform: translateX(150px);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes wordsLoop {
  0% {
    transform: translateX(150px);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes point {
  0% {
    top: 146px;
  }
  50% {
    top: 176px;
  }
  100% {
    top: 146px;
  }
}
</style>

<style scoped lang="scss">
.main-wrap {
  position: relative;
  background-image: url(/img/events/cardGame/main_bg@2x.png);
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100%;

  .clock-wrap {
    position: absolute;
    top: 24px;
    width: 100%;
  }

  .notice {
    position: absolute;
    top: 78px;
    width: 205px;
    left: 50%;
    transform: translate(-50%);
    height: 28px;
    background: rgba(255, 255, 255, 0.19);
    border-radius: 15px;
    overflow: hidden;

    .p-wrap {
      overflow: hidden;

      p {
        white-space: nowrap;
        animation: 20s wordsLoop linear infinite normal;
        -webkit-animation: 20s wordsLoop linear infinite normal;
        display: inline-block;
      }
    }
  }

  .point-img {
    position: absolute;
    top: 146px;
    left: 50%;
    width: 52px;
    height: 73px;
    margin-left: -26px;
    z-index: 1;
    animation: .8s point ease infinite normal;
  }

  .box-bg {
    position: absolute;
    top: 160px;
    left: 50%;
    width: 258px;
    height: 202px;
    margin-left: -129px;
  }

  .init-po {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 288px;
  }

  .init-card {
    background-image: url(/img/events/cardGame/card_back@2x.png);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 108px;
    height: 144px;
    transform: rotate(-20deg) translate(-50%);
    position: absolute;
    top: 218px;
    left: 50%;
    transform-style: preserve-3d;
  }
}

.card-wrap {
  width: 108px;
  height: 144px;
  transform: translate(-50%);
  position: absolute;
  top: 135px;
  left: 50%;
  transform-style: preserve-3d;
  z-index: 1;

  .front, .back {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
  }

  .back {
    z-index: 1;
    backface-visibility: hidden;
    background-image: url(/img/events/cardGame/card_back@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .front {
    transform: rotateY(-180deg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

.holder-wrap {
  position: absolute;
  top: 400px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 32px;

  .holder-item {
    width: 88px;
    height: 112px;
    background-image: url(/img/events/cardGame/card_holder@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

.tips {
  position: absolute;
  top: 550px;
  color: var(--color-white);
  width: 100%;

  :first-child {
    font-size: 15px;
    margin-bottom: 12px;
  }
}

.step1 {
  animation-name: step1;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

.step2_0 {
  animation-name: step2_0;
  animation-duration: .3s;
  animation-fill-mode: forwards;
}

.step2_1 {
  animation-name: step2_1;
  animation-duration: .3s;
  animation-fill-mode: forwards;
}

.step2_2 {
  animation-name: step2_2;
  animation-duration: .3s;
  animation-fill-mode: forwards;
}

.step3_0 {
  animation-name: step3_0;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

.step3_1 {
  animation-name: step3_1;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

.step3_2 {
  animation-name: step3_2;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

.step4_0 {
  animation-name: step4_0;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

.step4_1 {
  animation-name: step4_1;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

.step4_2 {
  animation-name: step4_2;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}
</style>
